import React, { FC } from 'react';
import { classes } from './NotificationItem.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';

type Time = string | number | Date;
interface Props {
  time: Time;
}

const LAST_WEEK = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
function isWeekBefore(time: Time) {
  return new Date(time).getTime() < LAST_WEEK;
}

export const TimeAgo: FC<Props> = ({ time }) => {
  const { t } = useTranslation();

  return (
    <Text className={classes.content}>
      {isWeekBefore(time)
        ? t('app.widget.notification-item.time-ago', { time })
        : t('app.widget.notification-item.time-ago.relative', { time })}
    </Text>
  );
};
