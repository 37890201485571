import React, { FC } from 'react';
import { useTranslation, WidgetProps } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../settingsParams';
import { st, classes } from './Widget.st.css';
import { QueryViewerFeedNotificationsResponse } from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/types';
import { NotificationItem } from './components/notification-item';
import { Divider } from 'wix-ui-tpa';

export type ControllerProps = {
  notificationsRes?: QueryViewerFeedNotificationsResponse;
};

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const notifications = props.notificationsRes?.viewerFeedNotifications;

  return (
    <div className={st(classes.root, {})} data-hook="OoiNotifications-wrapper">
      <div className={classes.header}>
        <h2 data-hook="app-title">{t('app.widget.title')}</h2>
      </div>
      <Divider />
      <div className={classes.notificationsList}>
        {notifications?.map((n) => (
          <>
            <NotificationItem notification={n} />
            <Divider />
          </>
        ))}
      </div>
    </div>
  );
};

export default Widget;
