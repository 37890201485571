import React, { FC } from 'react';
// import classNames from 'classnames';
import { Avatar, Text } from 'wix-ui-tpa/cssVars';
import Link from '../link';
// import styles from './notification-list-item.scss';
// import {
//   INDICATOR_BACKGROUND_COLOR,
//   PRIMARY_FONT,
//   TEXT_COLOR,
// } from '../../constants/class-names';
// import { isSite } from '../../../common/services/wix-sdk-utils';
import { st, classes } from './NotificationItem.st.css';
import { MembersViewerFeedNotification } from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/types';
import { TimeAgo } from './TimeAgo';

interface Props {
  notification: MembersViewerFeedNotification;
  onClick?(notification: MembersViewerFeedNotification, url: string): void;
}

export const NotificationItem: FC<Props> = (props) => {
  const { notification } = props;
  const url = notification.deeplink?.webUrlData?.webUrl!;
  const isRead = false;
  // notification.status === 'READ' || notification.status === 'RESOLVED';

  return (
    <Link
      testId={`notification-item-${notification.id}`}
      to={url}
      onClick={() => {
        // props.onClick(notification, url);
      }}
      appDefinitionId={notification.appMetadata?.appId!}
      forceFullPageNavigation={true}
      // forceFullPageNavigation={isSite()}
    >
      <div className={classes.row}>
        <div style={{ marginRight: '18px' }}>
          <Avatar src={notification.icon?.personAvatarData?.data} />
        </div>
        <Text className={classes.content}>{notification.message}</Text>
        <div>
          <TimeAgo time={notification.sentDate!} />
        </div>
        <div className={classes.dotWrapper}>
          {!isRead ? <span className={classes.dot} /> : null}
        </div>
      </div>
    </Link>
  );
};
