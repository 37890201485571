import React, { FC } from 'react';
// import Wix from '../../../common/services/wix-sdk-polyfill';

const isModifiedEvent = (event: MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

interface Props {
  children: React.ReactNode;
  to: string;
  onClick(): void;
  appDefinitionId: string;
  forceFullPageNavigation: boolean;
  ariaLabel?: string;
  testId: string;
}

export const Link: FC<Props> = ({
  children,
  to,
  onClick,
  appDefinitionId,
  forceFullPageNavigation,
  ariaLabel,
  testId,
}) => {
  const handleClick = (event: any) => {
    onClick();
    if (!isModifiedEvent(event) && !forceFullPageNavigation) {
      event.preventDefault();
      event.stopPropagation();

      // Wix.Utils.navigateToSection(
      //   {
      //     appDefinitionId,
      //     shouldRefreshIframe: false,
      //   },
      //   '',
      // );
    }
  };

  return (
    <a
      target="_top"
      href={to}
      onClick={(event) => handleClick(event)}
      aria-label={ariaLabel}
      data-testid={testId}
    >
      {children}
    </a>
  );
};

export default Link;
